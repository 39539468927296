var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"migration-table"},[(_vm.ajaxCompleted && !_vm.hasCommands)?_c('div',[_c('h3',[_vm._v("There are no scripts available for the OS '"+_vm._s(_vm.device.system.os)+"'")])]):_c('div',[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Script Name")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Success")]),_c('th',[_vm._v("Roles")]),_c('th',[_vm._v("Auto")]),(_vm.canEditCommands)?_c('th'):_vm._e()])]),(_vm.ajaxCompleted)?_c('tbody',[_vm._l((_vm.commandsDone),function(command){return _c('tr',{key:command._id,class:{ disabled: command.disabled }},[_c('td',[(command.script !== null)?_c('span',{on:{"click":function($event){return _vm.showOutputModal(command._id)}}},[_vm._v(_vm._s(command.script.description))]):_c('span',[_vm._v("unknown script")])]),_c('td',[(command.completed)?_c('span',[_c('i',{staticClass:"uil uil-check-circle migration-status green"}),_vm._v(" Complete ")]):(command.disabled)?_c('span',[_vm._v(" Disabled ")]):_c('span',[_c('i',{staticClass:"uil uil-question-circle migration-status orange"}),_vm._v(" Pending ")])]),_c('td',[(
                command.success !== null &&
                typeof command.success !== 'undefined' &&
                command.success === true
              )?_c('i',{staticClass:"uil uil-check-circle migration-status green"}):(
                command.success !== null &&
                typeof command.success !== 'undefined' &&
                command.success === false
              )?_c('i',{staticClass:"uil uil-times-circle migration-status red"}):_c('span',[_vm._v("-")])]),_c('td',[_vm._v(" "+_vm._s(command.script.tags && command.script.tags.length ? command.script.tags.join(' | ') : '-')+" ")]),_c('td',[(command.auto)?_c('i',{staticClass:"uil uil-check-circle green"}):_c('i',{staticClass:"uil uil-circle"})]),(_vm.canEditCommands)?_c('td',[(
                (command.completed === null || typeof command.completed === 'undefined') &&
                !command.disabled
              )?_c('a',{staticClass:"link",on:{"click":function($event){return _vm.removeScript(command._id)}}},[_vm._v("Remove script")]):(
                (command.success !== null &&
                  typeof command.success !== 'undefined' &&
                  command.success === false) ||
                command.script.reassignable
              )?_c('a',{staticClass:"link",on:{"click":function($event){return _vm.reassignScript(command._id, command.script._id)}}},[_vm._v("Re-assign script")]):_vm._e()]):_vm._e()])}),_vm._l((_vm.commandsNew),function(script){return _c('tr',{key:script._id},[_c('td',[_vm._v(" "+_vm._s(script.description)+" ")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v(" "+_vm._s(script.tags && script.tags.length ? script.tags.join(' | ') : '-')+" ")]),_c('td'),(_vm.canEditCommands)?_c('td',[_c('a',{staticClass:"link",on:{"click":function($event){return _vm.runScript(script._id)}}},[_vm._v("Assign")]),_vm._v(" | "),_c('a',{staticClass:"link",on:{"click":function($event){return _vm.disableScript(script._id)}}},[_vm._v("Disable")])]):_vm._e()])})],2):_c('tbody',[_c('tr',[_c('td',{staticClass:"loader",attrs:{"colspan":"3"}},[_c('img',{attrs:{"src":_vm.loadingImage}})])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }