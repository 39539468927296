/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import Utils from '../../utils';

type MapFilter = {
  id: string;
  groupId: string;
  operator: string;
  filterId: string;
  type: string;
  input: string;
  field: string;
  isValid: boolean;
  value: string;
};

type Rule = {
  id: string;
  field: string;
  input: string;
  operator: string;
  value: string;
  groupId?: string;
};

type Group = {
  id: string;
  parentId: string;
  scope: string;
};

type State = {
  groups: Group[];
  rules: MapFilter[];
  parsedQuery: QueryRule | null;
  availableFilters: AvailableFilter[];
};

type AvailableFilter = {
  id: string;
  field: string;
  label: string;
  type: string;
  input: string;
  values: Record<string, any>;
  validation: Record<string, any>;
  default_value: boolean;
  operators: string[];
};

type QueryRule = {
  condition?: string;
  rules: Array<Rule | QueryRule>;
};

const state = () => ({
  availableFilters: [],
  groups: [],
  rules: [],
  parsedQuery: null,
});

const mapFilterToRule = (mapFilter: MapFilter): Rule => ({
  id: mapFilter.filterId,
  field: mapFilter.field,
  input: mapFilter.input,
  operator: mapFilter.operator,
  value: mapFilter.value,
});

const parseQueryStep = (state: State, groupId: string) => {
  const groups = state.groups.filter((group) => group.parentId === groupId);
  const rules = state.rules
    .filter((rule) => rule.groupId === groupId)
    .map((rule) => mapFilterToRule(rule));

  const queryRule: QueryRule = {
    condition: state.groups.find((group) => group.id === groupId)?.scope,
    rules,
  };
  groups.forEach((group) => {
    const parsedRules = parseQueryStep(state, group.id);
    queryRule.rules.push(parsedRules);
  });
  return queryRule;
};

const actions = {
  async getAvailableFilters({ commit }: { commit: (a: string, b: any) => void }, flavor: string) {
    const response = await Utils.post(
      '/api/v1/alerts/helper/filter',
      {
        flavor,
      },
      this,
    );

    if (response.success) {
      commit('setAvailableFilters', response.filters);
    }
  },
  parseQuery({ commit, state }: { commit: (a: string, b: any) => void; state: State }) {
    if (!state.rules.some((rule: MapFilter) => rule.isValid === false)) {
      const firstGroup = state.groups.find((group) => !group.parentId);
      if (!firstGroup) return;

      commit('setParsedQuery', parseQueryStep(state, firstGroup.id));
    } else {
      commit('setParsedQuery', null);
    }
  },
};

const getters = {
  availableFilters: (state: State) => state.availableFilters,
  getRulesByGroupId: (state: State) => (groupId: string) =>
    state.rules.filter((rule) => rule.groupId === groupId),
  getChildGroups(state: State, groupId: string) {
    return state.groups.filter((group) => group.parentId === groupId);
  },
  getParsedQuery(state: State) {
    return state.parsedQuery;
  },
};

const mutations = {
  resetValues(state: State) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = {
      availableFilters: [],
      groups: [],
      rules: [],
      parsedQuery: null,
    };
  },
  setParsedQuery(state: State, parsedQuery: QueryRule) {
    state.parsedQuery = parsedQuery;
  },
  setAvailableFilters(state: State, filters: AvailableFilter[]) {
    state.availableFilters = filters;
  },
  addGroup(state: State, group: Group) {
    state.groups.push(group);
  },
  changeGroup(state: State, group: Group) {
    const groupToEdit = state.groups.find((existingRule) => existingRule.id === group.id);
    Object.assign(groupToEdit, group);
  },
  deleteGroup(state: State, groupId: string) {
    const existingGroup = state.groups.find((existingGroup: Group) => existingGroup.id === groupId);
    if (!existingGroup) return;

    state.groups.splice(state.groups.indexOf(existingGroup));
    state.rules = state.rules.filter((rule) => rule.groupId !== groupId);
  },
  addRule(state: State, mapFilter: MapFilter) {
    state.rules.push(mapFilter);
  },
  updateRuleData(state: State, rule: Rule) {
    const ruleToEdit = state.rules.find((existingRule) => existingRule.id === rule.id);
    Object.assign(ruleToEdit, rule);
  },
  deleteRule(state: State, ruleId: string) {
    const ruleToEdit = state.rules.find((existingRule) => existingRule.id === ruleId);
    if (!ruleToEdit) return;

    state.rules.splice(state.rules.indexOf(ruleToEdit));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
