<template>
  <div class="device-viewer" v-if="devices.length !== 0">
    <h1 class="title">Manage Multiple Devices</h1>
    <h4 class="sub-name">{{ devices.length }} devices selected</h4>
    <tabs class="action-tabs">
      <tab title="Actions">
        <div class="card-title">Action</div>
        <select name="status-online" class="input-select" v-model="action">
          <option value="">---</option>
          <optgroup label="Tags">
            <option value="tag:add">Add a tag</option>
            <option value="tag:remove">Remove a tag</option>
          </optgroup>
          <optgroup label="Roles">
            <option value="role:add">Add a role</option>
            <option value="role:remove">Remove a role</option>
          </optgroup>
          <optgroup label="Variables">
            <option value="variable:add">Add a variable</option>
            <option value="variable:remove">Remove a variable</option>
          </optgroup>
          <optgroup label="Status">
            <option value="status:reboot">Reboot device</option>
            <option value="status:restart:wc">Restart Wherever</option>
            <option value="status:update:wc">Update Wherever</option>
          </optgroup>
          <optgroup label="MagicINFO Player">
            <option value="magicinfo:download-player-content">Download content</option>
          </optgroup>
        </select>
        <div v-if="action.startsWith('tag')" style="margin-top: 10px">
          <vue-tags-input
            class="tags-input"
            v-model="tag"
            :tags="tags"
            :autocomplete-min-length="0"
            :autocomplete-items="filteredTags"
            :add-only-from-autocomplete="true"
            placeholder="Enter one or more tags"
            @tags-changed="(newTags) => (tags = newTags)"
          />
        </div>
        <div v-if="action.startsWith('role')" style="margin-top: 10px">
          <vue-tags-input
            class="tags-input"
            v-model="roletag"
            :tags="roletags"
            :autocomplete-min-length="0"
            :autocomplete-items="filteredRoleTags"
            :add-only-from-autocomplete="true"
            placeholder="Enter one or more roletags"
            @tags-changed="(newRoleTags) => (roletags = newRoleTags)"
          />
        </div>
        <div v-if="action.startsWith('variable:add')" style="margin-top: 10px">
          <p>
            Enter a KEY name and a VALUE. All keys will be fed to scripts as
            <code>WHEREVER_CLIENT_$KEY</code> (uppercased)
          </p>
          <grid>
            <grid-col size="2">
              <input
                type="text"
                placeholder="Key"
                class="input-text variable"
                name="variable-key"
                v-model="variable.key"
              />
            </grid-col>
            <grid-col size="2">
              <input
                type="text"
                placeholder="Value"
                class="input-text variable"
                name="variable-value"
                v-model="variable.value"
              />
            </grid-col>
          </grid>
        </div>
        <div v-if="action.startsWith('variable:remove')" style="margin-top: 10px">
          <p v-if="variables.length">
            Listing all variables unique across all selected devices. Please select the ones you
            would like to remove.
          </p>
          <p v-else>No variables found in selected devices</p>
          <label v-for="variable in variables" :key="variable">
            <input type="checkbox" :value="variable" v-model="variablesChecked" /> {{ variable }}
          </label>
        </div>
        <div class="property">
          <base-button
            v-on:click="executeAction"
            type="THEMED"
            isSmall
            title="Execute on selection"
          />
          <table-component
            :tableData="devices"
            :columns="overviewColumns"
            :show-online-status="true"
            :showTags="true"
            :showRoles="true"
            :showVariables="true"
          />
        </div>
      </tab>
      <tab title="View script info"><script-view :devices="devices" /> </tab>
    </tabs>
    <div class="left">
      <base-button
        type="THEMED"
        isSmall
        title="Clear selection"
        v-on:click="handleClearSelection"
      />
    </div>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import VueTagsInput from '@johmun/vue-tags-input';
import ReconnectingWebSocket from 'reconnecting-websocket';
import Tabs from '../Tabs/Tabs.vue';
import Tab from '../Tabs/Tab.vue';
import ScriptView from './ScriptView.vue';
import TableComponent from '../Table/TableComponent.vue';
import BaseButton from '../BaseButton/BaseButton.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';

export default {
  name: 'MultiOverview',
  components: {
    BaseButton,
    Grid,
    GridCol,
    ScriptView,
    Tab,
    TableComponent,
    Tabs,
    VueTagsInput,
  },
  computed: {
    filteredTags() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
    filteredRoleTags() {
      return this.autocompleteRoleTags.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
  },
  props: ['deviceIds'],
  data() {
    return {
      ajaxCompleted: false,
      devices: [],
      action: '',

      tag: '',
      tags: [],
      autocompleteItems: [],
      roletag: '',
      roletags: [],
      autocompleteRoleTags: [],

      webSocket: null,
      websocketKeepAlive: null,
      scriptStatusList: [],
      overviewColumns: [
        {
          title: 'Name',
          key: 'name',
        },
      ],
      variable: {
        key: '',
        value: '',
      },
      variables: [], // filled with unique variable key
      variablesChecked: [],
    };
  },
  watch: {
    deviceIds() {
      this.getDevices();
    },
    'variable.key': {
      handler(v) {
        this.variable.key = v.replace(/-/, '_');
      },
    },
  },
  methods: {
    async getDevices() {
      const response = await Utils.fetch(
        '/api/v1/devices/filtered',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filter: this.deviceIds,
          }),
        },
        this,
      ).then((res) => res.json());
      if (response.success) {
        this.ajaxCompleted = true;
        this.devices = response.devices;
        this.variables = [];
        this.devices.forEach((device) => {
          if (device.variables && device.variables.length) {
            this.variables.push(...device.variables.map((v) => v.key));
          }
        });
        this.variables = [...new Set(this.variables)]; // unique
      }
    },
    handleClearSelection() {
      this.$emit('clear');
    },
    async getTags() {
      const response = await Utils.fetch('/api/v1/tags', {}, this).then((res) => res.json());
      if (response.length > 0) {
        this.autocompleteItems = response.sort().map((e) => ({ text: e }));
      }
    },
    async getRoleTags() {
      const response = await Utils.fetch('/api/v1/roletags', {}, this).then((res) => res.json());
      if (response.length > 0) {
        this.autocompleteRoleTags = response.sort().map((e) => ({ text: e, classes: 'role' }));
      }
    },
    async executeAction() {
      switch (this.action) {
        case 'tag:add':
          this.addTags(this.tags);
          break;
        case 'tag:remove':
          this.removeTags(this.tags);
          break;
        case 'role:add':
          this.addTags(this.roletags, true);
          break;
        case 'role:remove':
          this.removeTags(this.roletags, true);
          break;
        case 'variable:add':
          this.addVariable();
          break;
        case 'variable:remove':
          this.removeVariable();
          break;
        case 'status:reboot':
          this.rebootDevices();
          break;
        case 'status:update:wc':
          this.updateWherever();
          break;
        case 'status:restart:wc':
          this.restartWherever();
          break;
        case 'magicinfo:download-player-content':
          this.magicinfoDownloadPlayerContent();
          break;
        default:
          break;
      }
    },
    async addTags(tags, role = false) {
      if (tags.length > 0) {
        const response = await Utils.fetch(
          '/api/v1/devices/batch/tags',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              filter: this.devices.map((d) => d._id),
              tags: tags.map((t) => t.text),
              role,
            }),
          },
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.getDevices();
          this.$noty.success(`The new ${role ? 'role(s)' : 'tag(s)'} were saved`);
        } else {
          this.$noty.warning(response.message);
        }

        this.tags = [];
        this.roletags = [];
      }
    },
    async removeTags(tags, role = false) {
      if (tags.length > 0) {
        const response = await Utils.fetch(
          '/api/v1/devices/batch/tags',
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              filter: this.devices.map((d) => d._id),
              tags: tags.map((t) => t.text),
              role,
            }),
          },
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.getDevices();
          this.$noty.success(`The ${role ? 'role(s)' : 'tag(s)'} were removed`);
        } else {
          this.$noty.warning(response.message);
        }

        this.tags = [];
        this.roletags = [];
      }
    },
    async addVariable() {
      if (this.variable.key && this.variable.value) {
        const response = await Utils.fetch(
          '/api/v1/devices/batch/variable',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              filter: this.devices.map((d) => d._id),
              variable: this.variable,
            }),
          },
          this,
        ).then((res) => res.json());

        if (response.success) {
          this.getDevices();
          this.$noty.success('The variable was added');
        } else {
          this.$noty.warning(response.message);
        }

        this.variable.key = '';
        this.variable.value = '';
      }
    },
    async removeVariable() {
      if (this.variablesChecked.length) {
        const response = await Utils.fetch(
          '/api/v1/devices/batch/variable',
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              filter: this.devices.map((d) => d._id),
              variables: this.variablesChecked,
            }),
          },
          this,
        ).then((res) => res.json());

        if (response.success) {
          this.getDevices();
          this.$noty.success('The variable(s) was/were removed');
        } else {
          this.$noty.warning(response.message);
        }

        this.variablesChecked = [];
      }
    },
    async rebootDevices() {
      const rebootDevicesCount = this.sendWebSocketMessage('reboot');

      if (rebootDevicesCount > 0) {
        this.$noty.success(`Sending reboot command to ${rebootDevicesCount} devices.`);
      }

      const displays = this.devices
        .filter((e) => e.system && e.system.os === 'magicinfo')
        .map((e) => e._id);
      if (displays.length > 0) {
        this.rebootDisplay(displays);
      }
    },
    async rebootDisplay(displays) {
      const response = await Utils.fetch(
        '/api/v1/miservers/display/reboot',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            devices: displays,
          }),
        },
        this,
      ).then((res) => res.json());

      if (response.success) {
        this.$noty.success(
          `Sending reboot command to ${response.successDevices.length} display(s).`,
        );
      } else {
        // eslint-disable-next-line no-console
        console.warn(response);
      }
    },
    sendWebSocketMessage(message, extraData = {}) {
      const selection = this.devices
        .filter((d) => d.system && d.system.os !== 'magicinfo')
        .map((d) => d._id);
      for (let i = 0; i < selection.length; i += 1) {
        this.webSocket.send(
          JSON.stringify({
            type: message,
            ...extraData,
            target: selection[i],
            jwt: localStorage.getItem('jwt'),
          }),
        );
      }

      return selection.length;
    },
    async restartWherever() {
      const restartDevicesCount = this.sendWebSocketMessage('restart');

      if (restartDevicesCount > 0) {
        this.$noty.success(
          `Sending restart Wherever Client command to ${restartDevicesCount} devices.`,
        );
      } else {
        this.$noty.warning('No compatible devices found to send a reboot command to.');
      }
    },
    async updateWherever() {
      const updateDevicesCount = this.sendWebSocketMessage('update');

      if (updateDevicesCount > 0) {
        this.$noty.success(
          `Sending update Wherever Client command to ${updateDevicesCount} devices.`,
        );
      } else {
        this.$noty.warning('No compatible devices found to send a reboot command to.');
      }
    },
    async magicinfoDownloadPlayerContent() {
      const actionCount = this.sendWebSocketMessage('magicplayerDownload', {
        downloadAction: 1,
      });

      if (actionCount > 0) {
        this.$noty.success(
          `Sending restart MagicINFO player content download command to ${actionCount} devices.`,
        );
      } else {
        this.$noty.warning('No compatible devices found to send a MagicINFO player content download to.');
      }
    },
    startWebSocket() {
      let protocol = 'ws';
      if (window.location.protocol.substring(4, 5) === 's') {
        protocol += 's';
      }

      const jwt = window.localStorage.getItem('jwt');
      const date = new Date(Date.now() + 60000 * 60 * 24).toUTCString();
      document.cookie = `X-Authorization=${jwt}; expires=${date}; path=/api/ws; SameSite=None; Secure`;
      this.webSocket = new ReconnectingWebSocket(`${protocol}://${window.location.host}/api/ws`);
      this.webSocket.onopen = () => {
        // eslint-disable-next-line no-console
        console.log('Opened the websocket connection with the server.');
        clearInterval(this.websocketKeepAlive);
        this.websocketKeepAlive = setInterval(() => {
          if (this.webSocket) {
            this.webSocket.send('ping');
          } else {
            clearInterval(this.websocketKeepAlive);
          }
        }, 15000);
      };
      this.webSocket.onclose = () => {
        // eslint-disable-next-line no-console
        console.log('Closed the websocket connection with the server.');
        clearInterval(this.websocketKeepAlive);
      };
    },
    stopWebSocket() {
      if (this.webSocket !== null) {
        this.webSocket.close();
        this.webSocket = null;
      }
    },
  },
  mounted() {
    this.startWebSocket();
    this.getTags();
    this.getRoleTags();
    this.getDevices();
  },
  beforeDestroy() {
    clearInterval(this.websocketKeepAlive);
    this.stopWebSocket();
  },
};
</script>
<style scoped lang="scss">
.input-text.variable {
  width: auto;
}
</style>
