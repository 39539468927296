<template>
  <div class="overlay" v-show="value">
    <div class="frame card">
      <div ref="contentHolder" class="content-holder">
        <div class="card-title">
          On/Off Timers
          <div class="right timezone-note">All times in {{ device.magicinfo.stats.TZ }}</div>
        </div>
        <div class="timer-table" v-bind:class="{ waiting: waiting }">
          <table>
            <thead>
              <tr>
                <th>Timer</th>
                <th>On Time</th>
                <th>&nbsp;</th>
                <th>Off Time</th>
                <th>&nbsp;</th>
                <th>Volume</th>
                <th>Source</th>
              </tr>
            </thead>
            <tbody v-for="(timer, key) in activeTimers" data-timer-row="1" :key="key">
              <tr class="timer-row" @click="timerRowClick(key)">
                <td>Timer #{{ timer.timerSeq }}</td>
                <td v-if="timer.timerOnStatus === '1'">
                  {{ timer.timerOnH }}:{{ timer.timerOnM
                  }}<!-- {{ timer.timerOnAmpm ? "AM" : "PM" }}-->
                </td>
                <td v-else>--:--</td>
                <td v-if="timer.timerOnStatus === '1'">
                  {{ manualDaysToString(timer.timerManualWeekday) }}
                </td>
                <td v-else />

                <td v-if="timer.timerOffStatus === '1'">
                  {{ timer.timerOffH }}:{{ timer.timerOffM
                  }}<!-- {{ timer.timerOffAmpm ? "AM" : "PM" }}-->
                </td>
                <td v-else>--:--</td>

                <td v-if="timer.timerOffStatus === '1'">
                  {{ manualDaysToString(timer.timerOffManualWeekday) }}
                </td>
                <td v-else />

                <td v-if="timer.timerOnStatus === '1'">
                  {{ timer.timerVolume }}
                </td>
                <td v-else>---</td>

                <td v-if="timer.timerOnStatus === '1'">
                  {{ mapSource(timer.timerSource) }}
                </td>
                <td v-else>---</td>
              </tr>
              <tr class="fold">
                <td>On Timer</td>
                <td class="label">Status</td>
                <td colspan="5">
                  <label>
                    <CheckboxProperty
                      v-model="timer.timerOnStatus"
                      data-true-value="1"
                      data-false-value="0"
                    />
                    Use On Timer
                  </label>
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOnStatus === '1'">
                <td />
                <td class="label">Day</td>
                <td colspan="5" v-if="timer.timerManualWeekdayList !== undefined">
                  <label v-for="(state, indx) in timer.timerManualWeekdayList" :key="indx">
                    <CheckboxProperty
                      v-model="timer.timerManualWeekdayList[indx]"
                      data-true-value="1"
                      data-false-value="0"
                      @input="updateDayString(timer)"
                    />
                    {{ dayIndexToString(parseInt(indx, 10)) }} </label
                  >&nbsp;
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOnStatus === '1'">
                <td />
                <td class="label">On Time</td>
                <td colspan="5">
                  <vue-timepicker
                    format="HH:mm"
                    v-model="timer.timeOnObject"
                    @change="handleOnTime($event, timer)"
                  />
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOnStatus === '1'">
                <td />
                <td class="label">Volume</td>
                <td colspan="5">
                  <input type="number" v-model="timer.timerVolume" />
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOnStatus === '1'">
                <td />
                <td class="label">Source</td>
                <td colspan="5">
                  <select v-model="timer.timerSource">
                    <option
                      v-for="(optName, optKey) in getSourceMapping()"
                      :value="optKey"
                      :key="optKey"
                    >
                      {{ optName }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOnStatus === '1'">
                <td />
                <td class="label">Holiday</td>
                <td colspan="5">
                  <label>
                    <CheckboxProperty v-model="timer.timerHolidayOnEnable" />
                    Apply</label
                  >
                </td>
              </tr>
              <tr class="fold row-break">
                <td>Off Timer</td>
                <td class="label">Status</td>
                <td colspan="5">
                  <label>
                    <CheckboxProperty
                      v-model="timer.timerOffStatus"
                      data-true-value="1"
                      data-false-value="0"
                    />
                    Use Off Timer</label
                  >
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOffStatus === '1'">
                <td />
                <td class="label">Day</td>
                <td colspan="5" v-if="timer.timerOffManualWeekdayList !== undefined">
                  <label v-for="(state, indx) in timer.timerOffManualWeekdayList" :key="indx">
                    <CheckboxProperty
                      v-model="timer.timerOffManualWeekdayList[indx]"
                      data-true-value="1"
                      data-false-value="0"
                      @input="updateDayString(timer)"
                    />
                    {{ dayIndexToString(parseInt(indx, 10)) }} </label
                  >&nbsp;
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOffStatus === '1'">
                <td />
                <td class="label">Off Time</td>
                <td colspan="5">
                  <vue-timepicker
                    format="HH:mm"
                    v-model="timer.timeOffObject"
                    @change="handleOffTime($event, timer)"
                  />
                </td>
              </tr>
              <tr class="fold" v-show="timer.timerOffStatus === '1'">
                <td />
                <td class="label">Holiday</td>
                <td colspan="5">
                  <label>
                    <CheckboxProperty v-model="timer.timerHolidayOffEnable" />
                    Apply</label
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="clearfix" style="margin-top: 14px">
          <div class="left" v-if="!waiting">
            <base-button
              isSmall
              type="themed-button"
              v-on:click="saveAndPush()"
              title="Save and push timers"
            />
          </div>
          <div class="right" v-if="!waiting">
            <base-button
              isSmall
              type="THEMED"
              iconType="CANCEL"
              v-on:click="cancelModal()"
              title="Cancel"
            />
          </div>
          <div class="left" v-if="waiting">
            <span class="loader">
              <img :src="loadingImage" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// TODO: Disabled eslint for now, as this wasnt an easy fix.
//  Someone should give this file some love and attention
/* eslint-disable */
import Utils from '@/utils';
import VueTimepicker from 'vue2-timepicker';
import CheckboxProperty from '../Property/CheckboxProperty.vue';
import 'vue2-timepicker/dist/VueTimepicker.css';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'MagicInfoDisplayTimers',
  props: {
    timers: Object,
    device: Object,
    value: Boolean,
  },
  components: {
    CheckboxProperty,
    VueTimepicker,
    BaseButton,
  },
  computed: {
    activeTimers() {
      return Object.values(this.timers).map((timer) => {
        if (timer == null) {
          return this.getEmptyTimer();
        }
        return timer;
      });
    },
  },
  data() {
    return {
      waiting: false,
    };
  },
  methods: {
    updateDayString(timer) {
      timer.timerManualWeekday = timer.timerManualWeekdayList.join('');
      timer.timerOffManualWeekday = timer.timerOffManualWeekdayList.join('');
    },
    handleOnTime(eventData, timer) {
      timer.timerOnH = eventData.data.HH;
      timer.timerOnM = eventData.data.mm;
      timer.timerOnAmpm = eventData.data.A === 'AM' ? '1' : '0';
    },
    handleOffTime(eventData, timer) {
      timer.timerOffH = eventData.data.HH;
      timer.timerOffM = eventData.data.mm;
      timer.timerOffAmpm = eventData.data.A === 'AM' ? '1' : '0';
    },
    closeTableRows() {
      const rows = document.querySelectorAll('[data-timer-row]');
      for (let i = 0; i < rows.length; i += 1) {
        rows[i].classList.remove('selected');
      }
    },
    cancelModal() {
      this.closeTableRows();
      this.$emit('input', false);
    },
    async saveAndPush() {
      const timers = this.getTimers();
      this.closeTableRows();

      this.$noty.info('Pushing the timers to the display. Please wait a few seconds...');
      this.waiting = true;

      const res = await Utils.post(
        `/api/v1/miservers/${this.device.magicinfo.miserver._id}/timers/${this.device.magicinfo.system.deviceId}`,
        {
          timers,
        },
        this,
      );

      this.waiting = false;

      if (res.success) {
        if (res.status.status) {
          this.$noty.success('Succesfully saved and pushed timers!');

          // Update the GUI with new timers.
          for (let i = 0; i < 7; i++) {
            if (
              typeof res.status.result.items[`timerConfTimer${(i + 1).toString()}`] !== 'undefined'
            ) {
              this.timers[(i + 1).toString()] =
                res.status.result.items[`timerConfTimer${(i + 1).toString()}`];
            }
          }
          this.handleDeviceTimers(this.timers);
          this.cancelModal();
        }
      }
    },
    getTimers() {
      const timers = [];

      for (const key in this.timers) {
        const timer = JSON.parse(JSON.stringify(this.timers[key]));

        timer.timerManualWeekday = timer.timerManualWeekdayList.join('');
        timer.timerOffManualWeekday = timer.timerOffManualWeekdayList.join('');

        timer.timerOnH = timer.timeOnObject.HH;
        timer.timerOnM = timer.timeOnObject.mm;

        if (!isNaN(timer.timeOnObject.HH) && parseInt(timer.timeOnObject.HH) < 12) {
          timer.timerOnAmpm = '1';
        } else {
          timer.timerOnAmpm = '0';
        }

        timer.timerOffH = timer.timeOffObject.HH;
        timer.timerOffM = timer.timeOffObject.mm;

        if (!isNaN(timer.timeOffObject.HH) && parseInt(timer.timeOffObject.HH) < 12) {
          timer.timerOffAmpm = '1';
        } else {
          timer.timerOffAmpm = '0';
        }

        timer.timerRepeat = '5';
        timer.timerOffRepeat = '5';

        if (timer.timerHolidayOnEnable && timer.timerHolidayOffEnable) {
          timer.timerHolidayEnable = '1';
        } else if (timer.timerHolidayOnEnable && !timer.timerHolidayOffEnable) {
          timer.timerHolidayEnable = '2';
        } else if (!timer.timerHolidayOnEnable && timer.timerHolidayOffEnable) {
          timer.timerHolidayEnable = '3';
        } else if (!timer.timerHolidayOnEnable && !timer.timerHolidayOffEnable) {
          timer.timerHolidayEnable = '0';
        }

        timer.timerSeq = timer.timerSeq.toString();

        delete timer.timerHolidayOnEnable;
        delete timer.timerHolidayOffEnable;
        delete timer.timerManualWeekdayList;
        delete timer.timerManualWeekdayString;
        delete timer.timerOffManualWeekdayString;
        delete timer.timerOffManualWeekdayList;
        delete timer.timeOnObject;
        delete timer.timeOffObject;

        timers.push(timer);
      }

      return timers;
    },
    timerRowClick(key) {
      const rows = document.querySelectorAll('[data-timer-row]');
      const wasActive = rows[key].classList.contains('selected');

      for (let i = 0; i < rows.length; i++) {
        rows[i].classList.remove('selected');
      }

      if (!wasActive) {
        rows[key].classList.add('selected');
      }
    },
    manualDaysToString(manualString) {
      const trimmed = manualString.replace(/^0+|0+$/g, '');
      if (trimmed.length === 0) {
        return '';
      }
      if (trimmed.length === 1) {
        const startIndex = manualString.indexOf('1');
        return this.dayIndexToString(startIndex);
      }
      if (trimmed.length > 1 && trimmed.indexOf('0') === -1) {
        // Show range
        const startIndex = manualString.indexOf('1');
        const endIndex = startIndex + trimmed.length - 1;

        return `${this.dayIndexToString(startIndex)}~${this.dayIndexToString(endIndex)}`;
      }
      // Show days
      const retList = [];

      for (let i = 0; i < manualString.length; i += 1) {
        if (manualString[i] === '1') {
          retList.push(this.dayIndexToString(i));
        }
      }
      return retList.join('-');
    },
    dayIndexToString(index) {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][index];
    },
    getSourceMapping() {
      return Utils.getSourceMapping();
    },
    mapSource(sourceCode) {
      return Utils.mapSource(sourceCode);
    },
    handleDeviceTimers() {
      for (const i in this.timers) {
        if (this.timers[i] == null) {
          this.timers[i] = this.getEmptyTimer(i);
        }

        // make sure that a timerManualWeekday string of 0000000 and repeat=2 is converted to selected business days
        if (this.timers[i].timerRepeat === '2' && this.timers[i].timerManualWeekday === '0000000') {
          this.timers[i].timerManualWeekday = '0111110';
        }

        // make sure that a timerManualWeekday string of 0000000 and repeat=1 is converted to all days
        if (this.timers[i].timerRepeat === '1' && this.timers[i].timerManualWeekday === '0000000') {
          this.timers[i].timerManualWeekday = '1111111';
        }

        // make sure that a timerOffManualWeekday string of 0000000 and repeat=2 is converted to selected business days
        if (
          this.timers[i].timerOffRepeat === '2' &&
          this.timers[i].timerOffManualWeekday === '0000000'
        ) {
          this.timers[i].timerOffManualWeekday = '0111110';
        }

        // make sure that a timerManualOffWeekday string of 0000000 and repeat=1 is converted to all days
        if (
          this.timers[i].timerOffRepeat === '1' &&
          this.timers[i].timerOffManualWeekday === '0000000'
        ) {
          this.timers[i].timerOffManualWeekday = '1111111';
        }

        if (typeof this.timers[i].timerManualWeekday === 'string') {
          this.timers[i].timerManualWeekdayList = this.timers[i].timerManualWeekday.split('');
          this.timers[i].timerManualWeekdayString = this.manualDaysToString(
            this.timers[i].timerManualWeekday,
          );
        }

        if (typeof this.timers[i].timerOffManualWeekday === 'string') {
          this.timers[i].timerOffManualWeekdayList = this.timers[i].timerOffManualWeekday.split('');
          this.timers[i].timerOffManualWeekdayString = this.manualDaysToString(
            this.timers[i].timerOffManualWeekday,
          );
        }

        this.timers[i].timerHolidayOnEnable = false;
        this.timers[i].timerHolidayOffEnable = false;

        if (
          this.timers[i].timerHolidayEnable === '1' ||
          this.timers[i].timerHolidayEnable === '2'
        ) {
          this.timers[i].timerHolidayOnEnable = true;
        }
        if (
          this.timers[i].timerHolidayEnable === '1' ||
          this.timers[i].timerHolidayEnable === '3'
        ) {
          this.timers[i].timerHolidayOffEnable = true;
        }

        this.timers[i].timeOnObject = {
          HH: this.timers[i].timerOnH,
          mm: this.timers[i].timerOnM,
        };

        this.timers[i].timeOffObject = {
          HH: this.timers[i].timerOffH,
          mm: this.timers[i].timerOffM,
        };
      }
    },
    getEmptyTimer(seq) {
      return {
        timerSeq: seq,
        timerOnH: '00',
        timerOnM: '00',
        timerOnAmpm: '1',
        timerOnStatus: '0',
        timerOffH: '00',
        timerOffM: '00',
        timerOffAmpm: '0',
        timerOffStatus: '0',
        timerRepeat: '0',
        timerManualWeekday: '0000000',
        timerOffRepeat: '0',
        timerOffManualWeekday: '0000000',
        timerVolume: '0',
        timerSource: '96',
        timerHolidayEnable: '0',
        timerDataCount: 0,
      };
    },
  },
  mounted() {
    if (this.timers) {
      this.handleDeviceTimers();
    }
  },
};
</script>
<style scoped lang="scss">
.overlay {
  .frame {
    font-size: 13px;

    select {
      font-size: 12px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; /* Remove default arrow */
      background-image: url('~@/assets/angle-down.svg');

      background-color: rgba(255, 255, 255, 0.1);
      background-repeat: no-repeat;
      background-position: right;
      border: 1px solid rgba(0, 0, 0, 0.4);
      color: var(--font-color);
      border-radius: 3px;
      padding: 3px 5px;
      padding-right: 20px;
      position: relative;
      height: auto;

      option {
        color: black;
        height: auto;
      }
    }

    input[type='number'] {
      font-size: 12px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; /* Remove default arrow */

      border: 1px solid rgba(0, 0, 0, 0.4);
      color: var(--font-color);
      border-radius: 3px;
      padding: 3px 5px;
      background-color: rgba(255, 255, 255, 0.1);
      width: 60px;
    }

    .timer-table {
      max-height: 500px;
      overflow-y: scroll;
      border: 1px solid #23282c;
    }

    .timer-table table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        padding: 5px 6px;
      }

      tr.timer-row {
        cursor: pointer;
        color: #9ca7b0;
      }

      tbody.selected tr.timer-row {
        background: rgba(255, 255, 255, 0.1);
      }

      tr.fold {
        display: none;
        background: rgba(0, 0, 0, 0.2);

        &.row-break {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      tbody.selected tr.fold {
        display: table-row;
      }

      tbody td.label {
        font-size: 10px;
        text-transform: uppercase;
      }

      thead {
        tr th {
          background: #23282c;
        }
      }

      thead {
        text-align: left;
      }
    }
  }
}

.loader {
  padding: 20px 0;

  img {
    height: 30px;
  }
}

.timezone-note {
  color: #bcbcbc;
  font-size: 10pt;
}
</style>

<style lang="scss">
.vue__time-picker input.display-time {
  font-size: 13px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.4);
  color: #9ca7b0;
  border-radius: 3px;
  padding: 3px 5px;
  width: auto;
}

.waiting {
  opacity: 0.6;
  pointer-events: none;
}

.clear-btn {
  color: #2e343a;
}

.vue__time-picker .dropdown {
  background: #2f343a;
}

.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #c88f52;
}

.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):focus,
.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):hover {
  background: rgba(255, 255, 255, 0.08);
  color: white;
}

.vue__time-picker .dropdown ul li {
  color: white;
}

.vue__time-picker .dropdown ul.apms,
.vue__time-picker .dropdown ul.minutes,
.vue__time-picker .dropdown ul.seconds {
  border-left: 1px solid rgba(0, 0, 0, 0.4);
}
</style>
