import { render, staticRenderFns } from "./BaseButton.vue?vue&type=template&id=7cf613db&scoped=true&"
import script from "./BaseButton.vue?vue&type=script&lang=ts&"
export * from "./BaseButton.vue?vue&type=script&lang=ts&"
import style0 from "./BaseButton.vue?vue&type=style&index=0&id=7cf613db&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cf613db",
  null
  
)

export default component.exports