<template>
  <div class="property" v-bind:class="{ hidenspacing: hidespacing }">
    <div v-if="title" class="title">
      {{ title }}
    </div>
    <div class="tag-row" v-if="value && value.length > 0">
      <div
        v-for="v in value"
        :class="'tag ' + (tagAddClass ? tagAddClass : '')"
        :style="style"
        :key="v"
      >
        {{ v }}
      </div>
    </div>
    <div v-else>-</div>
  </div>
</template>

<script>
export default {
  name: 'TagProperty',
  props: {
    title: String,
    value: Array,
    hidespacing: Boolean,
    customStyle: String,
    tagAddClass: String,
  },
  computed: {
    style() {
      if (this.customStyle) {
        return this.customStyle;
      }
      return '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.property {
  margin-top: 10px;

  &.hidenspacing {
    margin: 0 !important;
  }

  .tag {
    background-color: #f8b36b;
    color: #1e2a31;
    border-radius: 2px;
    display: inline-block;
    padding: 3px 5px;
    margin: 2px;
    font-size: 11px;
  }

  .tag.role {
    background-color: #4dbd74;
  }

  .title {
    font-size: 10px;
    opacity: 0.8;
    text-transform: uppercase;
  }
}
</style>
