<template>
  <div v-if="device && device.stats">
    <card title="System">
      <div class="properties">
        <Property title="UUID">
          <span class="monospace">
            {{ device.hwUnique }}
          </span>
        </Property>
        <Property title="External IPv4">
          <span class="monospace">
            {{ device.system.externalIp }}
          </span>
        </Property>
        <Property v-if="device.system.externalIpv6" title="External IPv6">
          <span class="monospace">
            {{ device.system.externalIpv6 }}
          </span>
        </Property>
        <Property title="Internal IP">
          <span class="monospace">
            {{ device.system.internalIp }}
          </span>
        </Property>
        <Property title="Mac Address">
          <span class="monospace">
            {{ device.system.macAddress }}
          </span>
        </Property>
        <Property title="Hostname">
          <span class="monospace">
            {{ device.system.hostname || '-' }}
          </span>
        </Property>
        <Property title="Platform">
          {{ capitalize(device.system.os) }}
        </Property>
        <Property title="Processor" v-if="device.system.cpuName && device.system.cpuName !== ''">
          {{ device.system.cpuName }}
        </Property>
        <Property
          title="Active Processes"
          v-if="device.stats.processes && device.stats.processes.length > 0"
        >
          <a class="link" @click="showProcesses = true"
            >{{ device.stats.processes.length }} processes</a
          >
        </Property>
        <Property v-if="device.online" title="Up-Time">
          {{ $date(new Date(Date.now() - device.stats.upTime * 1000)).fromNow(true) }}
        </Property>
        <Property
          v-if="device.stats && device.stats.internetSpeed && device.stats.internetSpeed.current"
          title="Internet Speed"
        >
          {{
            device.stats.internetSpeed.current.down +
            ' Mbit/s down - ' +
            device.stats.internetSpeed.current.up +
            ' Mbit/s up'
          }}
        </Property>
      </div>
    </card>
    <Processes :device="device" :show="showProcesses" @close="showProcesses = false" />
  </div>
</template>

<script>
import Property from '../../Util/Property.vue';
import Processes from '../Modal/Processes.vue';
import Card from '../../Card/Card.vue';

export default {
  props: ['device'],
  components: {
    Property,
    Processes,
    Card,
  },
  data() {
    return {
      showProcesses: false,
    };
  },
  methods: {
    capitalize(input) {
      if (typeof input !== 'undefined') {
        return input.charAt(0).toUpperCase() + input.slice(1);
      }
      return '';
    },
  },
};
</script>
