
// eslint-disable-next-line object-curly-newline
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

@Component({
  name: 'DateRangePicker',
  components: {
    Card,
    BaseButton,
  },
})
export default class Graph extends Vue {
  $date: any;

  drpFormat = 'YYYY-MM-DD';

  dateFrom = '';

  dateTo = '';

  @Prop() datePickerOptions: any;

  @Watch('dateTo')
  dateToChanged(newVal?: string) {
    if (newVal) {
      this.$emit('dateToChanged', newVal);
    }
  }

  @Watch('dateFrom')
  dateFromChanged(newVal?: string) {
    if (newVal) {
      this.$emit('dateFromChanged', newVal);
    }
  }

  updateRanges(start: any, end: any) {
    this.dateFrom = start.format(this.drpFormat);
    this.dateTo = end.format(this.drpFormat);
  }

  // add or subtract a month from current range
  async addMonth(m: number) {
    const parsedFrom = new Date(this.dateFrom);
    const parsedTo = new Date(this.dateTo);
    parsedFrom.setMonth(parsedFrom.getMonth() + m);
    parsedTo.setMonth(parsedTo.getMonth() + m);

    this.dateFrom = parsedFrom.toISOString().slice(0, 10);
    // eslint-disable-next-line prefer-destructuring
    this.dateTo = parsedTo.toISOString().slice(0, 10);
  }

  async setupDatepicker() {
    // eslint-disable-next-line prefer-destructuring
    this.dateFrom = this.$date().startOf('month').format(this.drpFormat);
    // eslint-disable-next-line prefer-destructuring
    this.dateTo = this.$date().endOf('month').format(this.drpFormat);

    this.$emit('dateFromChanged', this.dateFrom);
    this.$emit('dateToChanged', this.dateTo);
  }

  mounted() {
    this.setupDatepicker();
  }
}
