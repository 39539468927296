import humanizeDuration from 'humanize-duration';

const sourceMapping = {
  4: 'S-Video',
  8: 'Component',
  12: 'AV',
  13: 'AV2',
  14: 'Ext',
  20: 'PC',
  24: 'DVI',
  30: 'BNC',
  31: 'DVI_VIDEO',
  32: 'MagicInfo',
  33: 'HDMI1',
  34: 'HDMI1_PC',
  35: 'HDMI2',
  36: 'HDMI2_PC',
  37: 'Display_Port',
  38: 'Display_Port2',
  48: 'ATV',
  49: 'HDMI3',
  50: 'HDMI3_PC',
  51: 'HDMI4',
  52: 'HDMI4_PC',
  64: 'DTV',
  80: 'Plug In Module',
  85: 'HDBaseT',
  96: 'MagicInfo-Lite/S',
  97: 'WiDi',
  101: 'WebBrowser',
};

const Utils = {
  duration(ms: number, options = {}) {
    return humanizeDuration(ms, options);
  },

  durationShort(ms: number, options = {}) {
    const shortEnglishHumanizer = humanizeDuration.humanizer({
      language: 'shortEn',
      languages: {
        shortEn: {
          y: () => 'y',
          mo: () => 'mo',
          w: () => 'w',
          d: () => 'd',
          h: () => 'h',
          m: () => 'm',
          s: () => 's',
          ms: () => 'ms',
        },
      },
    });
    return shortEnglishHumanizer(ms, options);
  },

  getUser(token?: string) {
    let user = null;
    const tokenToParse = token || localStorage.getItem('jwt');
    if (tokenToParse) {
      try {
        user = JSON.parse(atob(tokenToParse.split('.')[1]));
      } catch (e) {
        // Discard error
      }
    }

    return user;
  },

  async getOrganization() {
    const user = this.getUser();
    const url = `/api/v1/organizations/${user.organization}`;
    const response = await this.fetch(url, {}, this).then((res) => res?.json());
    return response.organization;
  },

  isAdmin() {
    let admin = false;
    const user = this.getUser();
    if (user && user.admin) {
      admin = true;
    }
    return admin;
  },

  userCan(perm: string, resource: string) {
    const user = this.getUser();
    if (user === null || typeof user.permissions === 'undefined') {
      return false;
    }

    if (typeof user.permissions[resource] === 'undefined') {
      return false;
    }

    if (user.permissions[resource][0] === '*' || user.permissions[resource].indexOf(perm) !== -1) {
      return true;
    }

    return false;
  },

  fetchRaw: (url: string, options: any) => fetch(url, options).then((res) => res.json()),

  fetchPlain: (url: string, options: any) => fetch(url, options),

  fetchPostRaw: (url: string, options: any) => {
    // eslint-disable-next-line no-param-reassign
    options.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
    // eslint-disable-next-line no-param-reassign
    options.method = 'POST';
    return fetch(url, options).then((res) => res.json());
  },

  // eslint-disable-next-line consistent-return
  fetch: async (url: string, options: any, context: any) => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      if (options) {
        if (options.headers) {
          // eslint-disable-next-line no-param-reassign
          options.headers['X-Access-Token'] = `JWT ${jwt}`;
        } else {
          // eslint-disable-next-line no-param-reassign
          options.headers = {
            'X-Access-Token': `JWT ${jwt}`,
          };
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        options = {
          headers: {
            'X-Access-Token': `JWT ${jwt}`,
          },
        };
      }
    }

    const response = await fetch(url, options).then((res) => res.json());

    if (!response.success && response.redirect) {
      context.$router.push('/logout');
    } else {
      return {
        json: async () => response,
      };
    }
  },

  getSourceMapping: () => sourceMapping,

  mapSource: (sourceCode: keyof typeof sourceMapping): string => {
    if (sourceMapping[sourceCode]) {
      return sourceMapping[sourceCode];
    }
    return '';
  },

  post: async (url: string, body: any, context: any) =>
    Utils.fetch(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      context,
    ).then((res) => res?.json()),

  delete: async (url: string, body: any, context: any) =>
    Utils.fetch(
      url,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      context,
    ).then((res) => res?.json()),
};

export default Utils;
