<template>
  <card v-if="alerts.length > 0" title="Unread alerts">
    <div class="card alert">
      <div class="explanation">
        This device was linked to one or more recent, unread alerts. Click on the alert name to go
        to that alert.
      </div>
      <div class="alerts">
        <table class="table">
          <thead>
            <tr>
              <th>Alert</th>
              <th>Alerted</th>
              <th>Timestamp</th>
              <th>Mark as read</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="alert in alerts" :key="alert._id">
              <td class="name" @click="goToAlert(alert._id)">{{ alert.alert.name }}</td>
              <td>{{ $date(alert.createdAt).fromNow() }}</td>
              <td>{{ $date(alert.createdAt).format('LLL') }}</td>
              <td>
                <a class="link" @click="mark(true, alert._id)">Mark as read</a>
              </td>
              <td>
                <a class="link" @click="remove(alert._id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </card>
</template>

<script>
import Utils from '@/utils';
import Card from '../Card/Card.vue';

export default {
  name: 'AlertStatus',
  props: ['device'],
  components: {
    Card,
  },
  data() {
    return {
      alerts: [],
    };
  },
  watch: {
    device() {
      this.alerts = [];
      this.getAlerts();
    },
  },
  methods: {
    async getAlerts() {
      const res = await Utils.fetch(`/api/v1/alerts/device/${this.device}`, {}, this).then((_res) =>
        _res.json(),
      );
      this.alerts = res.alerts;
    },
    goToAlert(alert) {
      this.$router.push(`/alerts?view=${alert}`);
    },
    async mark(read, id) {
      await Utils.fetch(
        `/api/v1/alerts/triggers/${read ? '' : 'un'}mark`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id,
          }),
        },
        this,
      )
        .then((res) => res.json())
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error.response);
        });
      this.$root.$emit('alertChange');
    },
    async remove(id) {
      await Utils.fetch(
        '/api/v1/alerts/triggers',
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id,
          }),
        },
        this,
      )
        .then((res) => res.json())
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error.response);
        });

      this.$root.$emit('alertChange');
    },
  },
  mounted() {
    this.getAlerts();
    this.$root.$on('alertChange', () => {
      this.getAlerts();
    });
  },
};
</script>
<style scoped lang="scss">
.card.alert {
  background: #4e3a42;
  border-color: #25171d;
  color: rgba(255, 255, 255, 0.6);
}

.table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-top: 10px;

  .name {
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;

    &:hover {
      color: white;
      text-decoration: underline;
    }
  }

  tr th:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  tr td:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  tr th,
  tr td {
    text-align: left;
    font-weight: normal;
    padding: 6px 10px;
  }

  thead tr {
    background: rgba(0, 0, 0, 0.1);

    th {
      font-size: 10px;
      text-transform: uppercase;
    }
  }
}
</style>
