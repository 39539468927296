<template>
  <card>
    <h4>
      Notes
      <i class="uil uil-pen edit-note" @click="state.editing = true" v-show="!state.editing" />
    </h4>
    <p class="last-saved" v-if="device.note && device.note.lastSaved">
      Saved {{ $date(device.note.lastSaved).fromNow() }}
    </p>
    <div
      class="notes-readonly"
      v-if="!state.editing && device.note && device.note.text !== null && device.note.text !== ''"
    >
      <div v-for="(line, index) in device.note.text.split(/\n/)" :key="index">
        {{ line || '&nbsp;' }}
      </div>
    </div>
    <div
      v-if="
        !state.editing && (!device.note || device.note.text === null || device.note.text === '')
      "
    >
      <p>No notes for this device</p>
    </div>

    <div class="notes-edit" v-if="state.editing">
      <textarea v-model="deviceData.note.text" />
      <div class="button-row">
        <base-button type="THEMED" iconType="SAVE" v-on:click="save()" title="Save" />
        <base-button
          type="THEMED"
          iconType="CANCEL"
          v-on:click="state.editing = false"
          title="Cancel"
        />
      </div>
    </div>
  </card>
</template>
<script>
import Utils from '@/utils';
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'ServiceNotes',
  components: {
    Card,
    BaseButton,
  },
  props: ['device'],
  data() {
    return {
      state: {
        editing: false,
      },
      deviceData: this.device,
    };
  },
  methods: {
    save() {
      this.state.editing = false;
      this.saveNote();
    },
    async getNote() {
      const response = await Utils.fetch(
        `/api/v1/devices/${this.device._id}/note`,
        {},
        this,
      ).then((res) => res.json());
      this.deviceData.note = response.note;
      // when times are equal, the responses time has ms added, like 456
      // remove this ms so the time is cut off to seconds
      this.deviceData.note.lastSaved = parseInt(this.deviceData.note.lastSaved / 1000, 10) * 1000;
    },
    async saveNote() {
      await Utils.fetch(
        `/api/v1/devices/${this.device._id}/note`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            note: this.device.note,
          }),
        },
        this,
      ).then((res) => res.json());
      this.$root.$emit('reloadTable');
      this.getNote();
    },
    initNote() {
      if (this.device.note === undefined) {
        this.deviceData.note = {
          text: null,
          lastSaved: null,
        };
      }
    },
  },
  watch: {
    'device._id': {
      handler() {
        this.initNote();
        this.getNote();
        this.state.editing = false;
      },
    },
  },
  mounted() {
    this.initNote();
    this.getNote();
  },
};
</script>

<style scoped lang="scss">
.notes-readonly {
  font-style: italic;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--background-color);
  color: var(font-color-disabled);
  max-height: 200px;
  overflow: scroll;
  div {
    white-space: pre;
  }
}

.notes-edit textarea {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--background-color);
  color: var(--font-color);
  width: 100%;
  box-sizing: border-box;
  height: 300px;
}

.button-row {
  padding: 4px 0;

  .note-button {
    // color: #ccc;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 6px 12px;
    margin-right: 3px;

    &.minor {
      background: rgba(0, 0, 0, 0.1);

      &:hover {
        background: rgba(0, 0, 0, 0);
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
.edit-note {
  cursor: pointer;
  font-size: 16px;
  opacity: 0.6;
}
</style>
