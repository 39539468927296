var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.device.magicinfo.stats !== 'undefined' &&
      typeof _vm.device.magicinfo.stats.previewCapture === 'string'
    )?_c('card',{attrs:{"title":"Content Preview"}},[_c('div',{staticClass:"preview-holder"},[_c('div',{staticClass:"preview-spinner"},[_c('img',{attrs:{"src":_vm.loadingImage}})]),_c('img',{ref:"preview",staticClass:"preview-img",attrs:{"src":_vm.device.magicinfo.stats.previewCapture,"data-base-img":_vm.device.magicinfo.stats.previewThumb
            ? _vm.device.magicinfo.stats.previewThumb
            : _vm.device.magicinfo.stats.previewCapture},on:{"load":_vm.handleImageLoad}})])]):_vm._e(),(_vm.device.magicinfo.stats !== undefined)?_c('card',{attrs:{"title":"MagicInfo Stats"}},[_c('Property',{attrs:{"title":"Server name"}},[_c('router-link',{attrs:{"to":`/miservers/${_vm.device.magicinfo.miserver._id}`}},[_vm._v(" "+_vm._s(_vm.device.magicinfo.miserver.name)+" ")])],1),_c('TextProperty',{attrs:{"title":"Server version","value":_vm.device.magicinfo.miserver.version}}),_c('TextProperty',{attrs:{"title":"Model","value":`${_vm.device.magicinfo.system.deviceModelName} -
       ${_vm.device.magicinfo.system.deviceType}`}}),(_vm.device.magicinfo.stats.screenSize && _vm.device.magicinfo.stats.resolution)?_c('TextProperty',{attrs:{"title":"Display","value":`${
        _vm.device.magicinfo.stats.screenSize
      }" (${_vm.device.magicinfo.stats.resolution.replace('*', 'x')})`}}):_vm._e(),(_vm.device.magicinfo.stats.firmwareVersion)?_c('TextProperty',{attrs:{"title":"Firmware version","value":_vm.device.magicinfo.stats.firmwareVersion}}):_vm._e(),(_vm.device.magicinfo.stats.deviceGroupName)?_c('TextProperty',{attrs:{"title":"Device group","value":_vm.device.magicinfo.stats.deviceGroupName}}):_vm._e(),(_vm.device.magicinfo.stats.contentScheduleName)?_c('TextProperty',{attrs:{"title":"Schedule name","value":_vm.device.magicinfo.stats.contentScheduleName}}):_vm._e(),(_vm.device.magicinfo.stats.tags && _vm.device.magicinfo.stats.tags.variable.length > 0)?_c('TagProperty',{attrs:{"title":"Variable tags","value":_vm.device.magicinfo.stats.tags.variable}}):_vm._e(),(_vm.device.magicinfo.stats.tags && _vm.device.magicinfo.stats.tags.media.length > 0)?_c('TagProperty',{attrs:{"title":"Media tags","value":_vm.device.magicinfo.stats.tags.media}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }