import { render, staticRenderFns } from "./Logout.vue?vue&type=template&id=b893abc0&scoped=true&"
import script from "./Logout.vue?vue&type=script&lang=js&"
export * from "./Logout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b893abc0",
  null
  
)

export default component.exports