import '@/plugins/bytestohuman';
import '@/plugins/dayjs';
import '@/plugins/loader';
import '@/plugins/pathutils';
import '@/plugins/utils';
import './plugins/noty/noty.scss';
import Clipboard from 'v-clipboard';
import Vue from 'vue';
import VueJsModal from 'vue-js-modal';
import NotyPlugin from './plugins/noty';
import App from './App.vue';
import router from './router';
import store from './store';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueGoogleMaps = require('vue2-google-maps');

Vue.use(NotyPlugin, {
  timeout: 4000,
  progressBar: true,
});

Vue.use(Clipboard);

Vue.use(VueJsModal, {
  componentName: 'Modal',
  dialog: true,
  dynamic: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDQCXbPkkwyeKe7f2oJ7IsWbox7bmn7xaI',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // // If you want to set the version, you can do so:
    // v: '3.26',
    region: 'US',
    language: 'en',
  },

  // // If you intend to programmatically custom event listener code
  // // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // // you might need to turn this on.
  // autobindAllEvents: false,

  // // If you want to manually install components, e.g.
  // // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // // Vue.component('GmapMarker', GmapMarker)
  // // then set installComponents to 'false'.
  // // If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/logout',
        query: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next();
    } else {
      next({ name: 'devices' });
    }
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
