<template>
  <div class="device-table" style="position: relative">
    <div class="filter-fixed" ref="filterColumn">
      <h1>{{ title }}</h1>
      <FilterWindow v-model="filter" :matched="filtered.length" @export="handleExport()" />
    </div>
    <div class="table-wrapper">
      <!-- Table for unapproved devices -->
      <table v-if="unapproved">
        <thead>
          <tr>
            <th>External IP</th>
            <th>Internal IP</th>
            <th>Mac Address</th>
            <th>First Seen</th>
          </tr>
        </thead>
        <tbody v-if="ajaxCompleted" class="device-table-body">
          <tr
            v-for="device in devices"
            @click="handleRowClick(device._id)"
            :data-id="device._id"
            :key="device._id"
          >
            <td>
              {{ device.system.externalIp }}
            </td>
            <td>
              {{ device.system.internalIp }}
            </td>
            <td>
              {{ device.system.macAddress }}
            </td>
            <td style="text-align: center">
              {{ $date(new Date(parseInt(device._id.substring(0, 8), 16) * 1000)).fromNow() }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5" class="loader">
              <img :src="loadingImage" />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Table for approved devices -->

      <table v-else>
        <thead>
          <tr v-if="manageColumns && columnSelect">
            <td
              :colspan="display.columns.length + 3"
              style="padding-left: 8px"
              @click="toggleColumnSelect"
              class="clickable"
            >
              <i class="uil uil-columns" /> Managing columns
              <base-button title="close" iconType="CANCEL" type="THEMED" :isSmall="true">
              </base-button>
            </td>
          </tr>
          <tr v-if="manageColumns && columnSelect">
            <td :colspan="display.columns.length + 3">
              <div>
                <vue-tags-input
                  class="tags-input"
                  v-model="tag"
                  :tags="tags"
                  :autocomplete-items="availableColumns"
                  placeholder="Select columns"
                  :autocomplete-min-length="0"
                  :add-only-from-autocomplete="true"
                  @tags-changed="(newColumns) => columnsChanged(newColumns)"
                />
              </div>
              <p />
            </td>
          </tr>
          <tr v-if="manageColumns && !columnSelect">
            <td
              :colspan="display.columns.length + 3"
              style="padding-left: 8px"
              @click="toggleColumnSelect"
              class="clickable"
            >
              <i class="uil uil-columns" v-if="manageColumns" /> Manage columns
            </td>
          </tr>
          <tr>
            <th>
              <CheckboxProperty @input="handleCheckAll" v-model="checkAll" />
            </th>
            <th>Status</th>
            <th class="os">OS</th>
            <th v-for="col in display.columns" :key="col">
              {{ col }}
            </th>
          </tr>
        </thead>
        <tbody v-if="ajaxCompleted">
          <tr
            v-for="device in filtered"
            @click="handleRowClick(device._id)"
            :key="device._id"
            :data-id="device._id"
          >
            <td>
              <CheckboxProperty v-model="device.checked" @input="handleCheckboxClick(device._id)" />
            </td>
            <td style="text-align: center; width: 10px">
              <i v-if="device.alert" class="uil uil-exclamation-triangle alert-name" />
              <span v-if="device.online !== false && device.vpn.active" class="blue bullet" />
              <span v-else-if="device.online !== false" class="green bullet" />
              <span v-else class="red bullet" />
            </td>
            <td class="os">
              <o-s-logo v-if="device.system.os == 'windows'" operatingSystem="WINDOWS" />
              <o-s-logo v-if="device.system.os == 'linux'" operatingSystem="LINUX" />
              <o-s-logo v-if="device.system.os == 'magicinfo'" operatingSystem="MAGICINFO" />
              <o-s-logo v-if="device.system.os == 'darwin'" operatingSystem="APPLE" />
            </td>
            <td v-for="col in display.columns" :key="col" :class="display.all[col].class">
              {{ display.all[col].field.split('.').reduce((o, i) => o[i], device) }}
              <span class="icon-doc note-icon" v-if="col == 'Name' && device.hasNote" />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6" class="loader">
              <img :src="loadingImage" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>&nbsp;</p>
  </div>
</template>

<script>
import Utils from '@/utils';
import Filter from '@/filter';
import VueTagsInput from '@johmun/vue-tags-input';
import { mapGetters } from 'vuex';
import CheckboxProperty from '../Property/CheckboxProperty.vue';
import FilterWindow from './Device/Filter.vue';
import OSLogo from '../OSLogo/OSLogo.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'deviceTable',
  props: ['events', 'unapproved', 'reload', 'title'],
  components: {
    CheckboxProperty,
    FilterWindow,
    VueTagsInput,
    OSLogo,
    BaseButton,
  },
  data() {
    return {
      isMounted: false,
      filter: Filter.empty(),
      autocompleteItems: [],
      ajaxCompleted: false,
      devices: [],
      selected: null,
      checkAll: false,
      manageColumns: true,
      columnSelect: false,
      tag: '',
      tags: [],
      display: {
        // labels of default visible columns
        defaultColums: ['Name', 'Mac Address', 'Client'],
        // labels of chosen visible columns
        columns: [],
        all: {
          Name: {
            field: 'name',
            header: 'Name',
            class: '',
          },
          'Mac Address': {
            field: 'system.macAddress',
            header: 'Mac Address',
            class: '',
          },
          'Int IP': {
            field: 'system.internalIp',
            header: 'Int IP',
            class: 'monospace',
          },
          'Ext IP': {
            field: 'system.externalIp',
            header: 'Ext IP',
            class: 'monospace',
          },
          Client: {
            field: 'clientVersion',
            header: 'Client',
            class: 'monospace',
          },
        },
      },
    };
  },
  watch: {
    events(device) {
      this.checkAndUpdateDevice(device);

      if (device.type && device.type === 'clearSelection') {
        this.handleClearSelection();
      }
    },
    reload() {
      this.getDevices();
    },
  },
  computed: {
    filtered() {
      const matched = Filter.match(this.devices, this.filter, this);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filter.matched = matched.length;
      return matched;
    },
    filteredTags() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.filter.tag.toLowerCase()) !== -1,
      );
    },
    filteredColumns() {
      return this.autocompleteItems.filter(
        (i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1,
      );
    },
    availableColumns() {
      const tagColumns = Object.keys(this.display.all).map((t) => ({ text: t }));
      return tagColumns.filter((i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1);
    },
    displayColumns() {
      const tagColumns = this.display.columns.map((t) => ({ text: t }));
      return tagColumns.filter((i) => i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1);
    },
    ...mapGetters('whereverConfig', { config: 'config' }),
  },
  methods: {
    columnsChanged(newColumns) {
      // array of objects: {text:'',class:''}
      this.tags = newColumns;
      this.saveColumns(this.tags.map((t) => t.text));
    },
    toggleColumnSelect() {
      this.columnSelect = !this.columnSelect;
    },
    saveColumns(columns) {
      this.display.columns = columns; // updates table
      localStorage.setItem(`columns-${this.$route.name}`, columns.join(','));
    },
    loadColumns() {
      const stored = localStorage.getItem(`columns-${this.$route.name}`);
      if (!stored) {
        this.display.columns = this.display.defaultColums;
      } else {
        this.display.columns = stored.split(',');
      }
      this.tags = this.display.columns.map((t) => ({ text: t }));
    },
    handleClearSelection() {
      this.checkAll = false;
      this.devices.forEach((e) => {
        e.checked = false;
      });
      this.selected = null;
      this.$emit('rowClick', this.selected);
    },
    handleCheckAll(input) {
      const newVal = input ? true : null;
      this.filtered.forEach((e) => {
        e.checked = newVal;
      });
      this.handleCheckboxClick();
    },
    handleCheckboxClick() {
      this.$nextTick(() => {
        const selectedDevices = this.devices.filter((e) => e.checked).map((e) => e._id);
        if (selectedDevices.length === 0) {
          this.selected = null;
        } else {
          this.selected = selectedDevices;
        }
        this.$emit('rowClick', this.selected);
      });
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    async getTags() {
      const response = await this.$utils.http().get('/api/v1/tags');
      this.autocompleteItems = response.data.map((e) => ({ text: e }));
    },
    async getDevices() {
      let response;
      if (this.unapproved) {
        response = await this.$utils.http().get('/api/v1/unapproved');
      } else {
        response = await this.$utils.http().get('/api/v1/devices');
      }
      this.ajaxCompleted = true;
      this.devices = response.data.map((e) => {
        e.checked = false;
        e.alert = false;
        return e;
      });
      this.getDevicesWithAlerts();

      if (this.config && this.config.modules && this.config.modules.restic) {
        this.getDevicesWithResticDelays();
      }
    },
    async getDevicesWithAlerts() {
      const response = await Utils.fetch('/api/v1/devices/alerts', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.devices = this.devices.map((e) => {
          e.alert = response.devices.indexOf(e._id) !== -1;
          return e;
        });
      }
    },
    async getDevicesWithResticDelays() {
      const response = await Utils.fetch('/api/v1/devices/restic-delays', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.devices = this.devices.map((e) => {
          e.alert = response.devices.indexOf(e._id) !== -1;
          return e;
        });
      }
    },
    async handleRowClick(id) {
      this.trySelectDevice(id);
      this.selected = id;
      this.$emit('rowClick', id);
    },
    async trySelectDevice(id) {
      const rows = document.querySelectorAll('.device-table tr');
      try {
        for (let i = 0; i < rows.length; i += 1) {
          rows[i].classList.remove('selected');
        }
      } catch (e) {
        // Continue regardless of error
      }

      const row = document.querySelector(`.device-table tr[data-id="${id}"]`);
      if (row) {
        row.classList.add('selected');
      }
    },
    async checkAndUpdateDevice(device) {
      for (let i = 0; i < this.devices.length; i += 1) {
        if (this.devices[i].hwUnique === device.target) {
          this.devices[i].online = device.online;
          this.devices[i].vpn.active = device.vpn;
        }
      }
    },
    async handleExport() {
      const response = await Utils.fetch(
        '/api/v1/devices/export',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            devices: this.filtered.map((e) => e.hwUnique),
          }),
        },
        this,
      ).then((res) => res.json());

      const fileBlob = this.b64toBlob(response.content, 'text/csv');
      const url = window.URL.createObjectURL(fileBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      const title = new Date().toISOString().replace(/[^a-zA-Z0-9]/g, '');
      a.download = `export-${title}.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
  },
  created() {
    Filter.init(this);
  },
  async mounted() {
    this.getDevices().then(() => {
      if (this.$route.params.device) {
        this.trySelectDevice(this.$route.params.device);
      }
    });
    this.$root.$on('reloadTable', () => {
      this.getDevices();
    });

    this.$root.$on('alertChange', () => {
      this.getDevicesWithAlerts();
    });

    this.loadColumns();

    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  height: 100%;
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
}

.device-table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.device-table table {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    //border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }

  .os {
    padding: 0;
    text-align: center;
  }
}

.bullet {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;

  &.blue {
    background: rgb(88, 195, 220);
  }

  &.green {
    background: #4dbd74;
  }

  &.red {
    background: #f86c6b;
  }
}

.note-icon {
  color: white;
  margin-left: 5px;
  font-size: 10px;
}

.alert-name {
  margin-right: 4px;
  color: #f52322;
}
</style>
<style lang="scss">
.ti-input {
  border-radius: 5px;
}

[name='filter-input'] {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
}

[name='status-online'],
[name='status-facet'],
[name='status-os'] {
  height: 36px;
}

.os img {
  opacity: 0.7;
  height: 13px;
}
</style>
