<template>
  <div class="properties properties-hidden">
    <div style="cursor: pointer;"
    @click="setVisibilityHidden">
      <i v-if="!visible" class="uil uil-angle-up" ></i>
      <i v-if="visible" class="uil uil-angle-down" ></i>
      {{ title }}
    </div>
    <ul  v-if="visible" style="margin-top: 5px;">
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ProgressProperty',
  props: {
    title: String,
    visible: Boolean,
  },
  methods: {
    setVisibilityHidden() {
      this.$emit('visibilityChanged', !this.visible);
    },
  },
};
</script>
