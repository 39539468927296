import { render, staticRenderFns } from "./CheckboxProperty.vue?vue&type=template&id=8b51ada8&scoped=true&"
import script from "./CheckboxProperty.vue?vue&type=script&lang=js&"
export * from "./CheckboxProperty.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxProperty.vue?vue&type=style&index=0&id=8b51ada8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.0_68125b3067bc9264b7f5e63a32c23d52/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b51ada8",
  null
  
)

export default component.exports